<template>
  <div>
    <VBtn class="mb-1 mx-0 px-0" depressed plain @click="onBack" x-small>
      <VIcon left>mdi-chevron-left</VIcon>
      {{ $t("btn.back") }}
    </VBtn>
    <VRow>
      <VCol class="pa-0 mt-2" cols="3">
        <VCard class="ml-4">
          <VCardText>
            <div class="info-img mb-0 d-flex justify-center" v-if="!mainItem?.path_img_thumb">
              <img
                :src="require('@/assets/images/bar-code-page.png')"
                alt=""
                style="height: 200px; width:80%; object-fit: cover"
              />
            </div>
            <div class="info-img mb-0 d-flex justify-center" v-else>
              <img style="height: 200px; width:80%; object-fit: cover" class="br-10" :src="mainItem?.path_img_thumb" alt="" />
            </div>
            <p class="subtitle-2 mb-0 mt-5 ">
              {{ $t("history.product") }}
            </p>

            <v-data-table
              elevation="0"
              disable-sort
              :headers="productsHeaders"
              :items="productsItems"
              :no-data-text="$t('table.noData')"
              locale="uk"
              dense
              hide-default-header
              hide-default-footer
            >
            <template v-slot:item="{ item }">
                <div class=" ml-4 d-flex align-center">
                  <VCol cols="5">
                    {{ item.column1 }}
                  </VCol>
                  <VCol cols="7">
                    {{ item.column2 }}
                  </VCol>
                </div>
              </template>
          </v-data-table>
            <VRow>
            <VCol>
              <p class="subtitle-2 mb-0 mt-2 ">
                {{ $t("history.placement") }}
              </p>
            </VCol>
            <VCol class="text-right">
              <VBtn @click="integrateDialog = true" icon color="gray">
                <VIcon>
                  mdi-link
                </VIcon>
              </VBtn>
            </VCol>
            </VRow>
            <v-data-table
              elevation="0"
              disable-sort
              :headers="placingHeaders"
              :items="placingItems"
              :no-data-text="$t('table.noData')"
              locale="uk"
              dense
              hide-default-header
              hide-default-footer
            >
            <template v-slot:item="{ item }">
                <div class="ml-4 d-flex align-center">
                  <VCol cols="5">
                    {{ item.column1 }}
                  </VCol>
                  <VCol cols="7">
                    {{ item.column2 }}
                  </VCol>
                </div>
              </template></v-data-table>
          </VCardText>
        </VCard>
      </VCol>
      <VCol class="pt-0 mt-1" cols="9">
        <ns-code-table
          :mainItem="mainItem"
        />
      </VCol>
    </VRow>
    <!--    <AddSupplyOutDialog-->
    <!--      :visible="addSupplyOutDialog"-->
    <!--      @close="addSupplyOutDialog = false; addSupplyItem = ''"-->
    <!--      :default-item="addSupplyItem"-->
    <!--      :states="[]"-->
    <!--    />-->

    <integration-dialog
      :visible="integrateDialog"
      v-if="integrateDialog"
      :product="mainItem"
      @close_modal="integrateDialog = false"
    />
  </div>
</template>

<script>
import _ from "lodash";
import depotService from "../../services/request/depot/depotService";
import loader from "../../mixins/loader";
import notifications from "../../mixins/notifications";
import language from "../../mixins/language";
import EventBus from "../../events/EventBus";
import supplyCart from "../../mixins/supplyCart";
// import AddSupplyOutDialog from '../../components/supply/AddSupplyOutDialog.vue';
import user from "../../mixins/user";
import paginationMixin from "../../mixins/paginationMixin";
import {uniqueByUUID, validationForNumbers} from "../../mixins/helpers";
import SizeUi from "../../mixins/SizeUi";
import chipTab from "@/components/common/chipTab.vue";
import IntegrationDialog from "@/components/products/integrationDialog.vue";
import ShowItemInfoDialog from "@/components/layout/ShowItemInfoDialog.vue";
import AddOptionDialog from "@/components/products/descriptions/AddOptionDialog.vue";
import {mapGetters} from "vuex";
import NsCodeTable from './components/nsCodeTable.vue';

export default {
  name: "ProductsByBarCode",
  mixins: [loader, notifications, language, user, supplyCart, paginationMixin, SizeUi],
  components: {
    AddOptionDialog,
    ShowItemInfoDialog,
    chipTab,
    IntegrationDialog,
    NsCodeTable
    // AddSupplyOutDialog,
  },
  data: () => ({
    integrateDialog: false,
    mainItem: [],
    addSupplyOutDialog: false,
    addSupplyItem: "",
    viewProductDialog: false,
    viewProduct: null,
    itemCapacity: "",


  }),
  methods: {

    getCapacity() {
      this.itemCapacity =
        (this?.mainItem.size_height * this?.mainItem.size_length * this?.mainItem.size_width) /
        1000000000;
    },
    onBack() {
      // if (this.getUser.type === 'client') {
      //   this.$router.push({name: 'products'});
      // } else {
      this.$router.push({ name: "products" }).catch(() => {});
      // }
    },
    getItemDetailsProp(prop) {
      return _.get(this.mainItem, `${prop}`);
    },


  },
  async mounted() {
    try {
      this.setLoading(true);
      this.mainItem = await depotService.getProduct(this.$route.params.barCode);
      // this.options.children.name = this.mainItem.options_details.name
      // this.options.children.amount = this.mainItem.options_details.count
      if (!this.mainItem) {
        // eslint-disable-next-line
        throw "Item Not Found";
      }
      this.setLoading(false);

      this.getCapacity();

    } catch (e) {
      this.setLoading(false);
      console.log(e)
    }
    EventBus.$on('product-updated', async () => {
      this.setLoading(true);
      this.mainItem = await depotService.getProduct(this.$route.params.barCode);
      this.setLoading(false);
    });
  },
  computed: {
    productsHeaders() {
      return [{ value: "column1" }, { value: "column2" }];
    },
    productsItems() {
      return [
        {
          column1: this.$t("table.name"),
          column2: this.getItemDetailsProp("name") || "--"
        },
      ];
    },
    placingHeaders() {
      return [{ value: "column1" }, { value: "column2" }];
    },
    placingItems() {
      return [
        {
          column1: this.$t("products.dimensions") + ` (${this.$t("catalog.mm")})`,

          column2: this?.capacity ? this.capacity  : "--"
        },
        {
          column1: this.$t("products.capacity") + ` (${this.$t("products.meter")}³)`,
          column2: this?.itemCapacity ? this.itemCapacity : "--"
        },
      ];
    },
    capacity(){
      return this.mainItem.size_height + 'x' + this.mainItem.size_length  + 'x' +  this.mainItem.size_width
    }
  },
};
</script>

<style lang="scss" scoped>
.products-list {
  &-header {
    background: #eeeeee;
    text-transform: uppercase;
    font-weight: bold;
    color: #676767;
    font-size: 18px;
    padding: 12px 0;
    border-radius: 12px 12px 0 0;
  }

  &-pagination {
    background: white;
  }

  &-data {
    background: white;

    > div {
      padding: 25px 5px;
      font-size: 15px;
    }
  }

  &-header,
  &-data {
    display: flex;
    margin-bottom: 5px;

    > div:nth-child(1) {
      width: 5%;
    }

    > div:nth-child(2),
    > div:nth-child(3),
    > div:nth-child(4) {
      width: 20%;
    }

    > div:nth-child(5) {
      width: 15%;
    }

    > div:nth-child(6) {
      width: 15%;
    }
  }
}
</style>
