<template>
  <VDialog v-model="visibility" width="500px"  :transition="$dialogTransition">
    <VCard :loading="loading">
      <VCardTitle>
        <VIcon color="grey darken-2" class="mr-1">
          mdi-plus-network-outline
        </VIcon>
       {{$t('catalog.add_new_option')}}
      </VCardTitle>
      <VDivider class="mx-5 mb-3" />
      <VCardText>
        <VRow>
          <VCol>
            <VTextField
              outlined
              :label="$t('form.title')"
              dense
              maxLength="50"
              hide-details="auto"
              v-model="newOption.name"
              :error-messages="nameError"
              clearable
              color="success"
            />
          </VCol>
        </VRow>
        <VRow>
          <VCol>
            <v-textarea
              outlined
              :label="$t('form.description')"
              dense
              hide-details="auto"
              v-model="newOption.description"
              rows="3"
              counter="150"
              maxLength="150"
              color="success"
            >
            <template slot="append">
              <v-slide-x-transition v-if="newOption.description.length > 0">
                <VIcon
                  @click="newOption.description = ''"
                  class="ml-2"
                  :disabled="loading">
                  mdi-close
                </VIcon>
              </v-slide-x-transition>
            </template>
            </v-textarea>
          </VCol>
        </VRow>
      </VCardText>
      <VDivider class="mx-5" />
      <VCardActions>
        <VCol xl="6" md="6" sm="6" xm="12">
          <VBtn block dark class="br-10 text-transform-none" @click="onCancel">
            {{ $t("btn.cancel") }}
          </VBtn>
        </VCol>
        <VCol>
          <VBtn
            block
            class="br-10 success-bg white--text text-transform-none"
            @click="onCreate"
            :loading="loading"
            :disabled="btnDisabled"
          >
            {{ $t("btn.create") }}
          </VBtn>
        </VCol>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import depotService from "@/services/request/depot/depotService";
import language from "@/mixins/language";
import notifications from "@/mixins/notifications";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    visible: {
      require: true
    },
    uuid_parent: {
      require: true
    }
  },
  data: () => ({
    newOption: {
      name: "",
      description: ""
    },
    loading: false,
    btnDisabled: false
  }),
  methods: {
    async onCreate() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append("name", this.newOption.name);
        formData.append("uuid_parent", this.uuid_parent);
        formData.append("description", this.newOption.description);
        const response = await depotService.addProductOptions(formData);
        this.$emit('updateOptions',response)
        this.resetFields();
         this.$emit("close");
      } catch (e) {
        console.log(e)
      } finally {
        this.$v.$reset();
        this.loading = false;
      }
    },
    onCancel() {
      this.resetFields();
      this.$v.$reset();
      this.$emit("close");
    },
    resetFields() {
      this.newOption = { name: "", description: "" };
    }
  },
  mounted() {
    this.btnDisabled = this.newOption.name == "" && this.newOption.description == "";
  },
  watch: {
    newOption: {
      deep: true,
      handler(e) {
        this.btnDisabled = this.newOption.name == "" && this.newOption.description == "";
      }
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      }
    },
    nameError() {
      const errors = [];
      if (!this.$v.newOption.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.newOption.name.required && errors.push(this.$t('form.errors.NameRequired'));
      return errors;
    }
  },
  validations: {
    newOption: { name: { required } }
  },
  mixins: [notifications, language, validationMixin]
};
</script>

<style scope lang="scss">
textarea {
  cursor: auto;
}
textarea::-webkit-scrollbar{
  width: 6px;
}
</style>
